import { ErrorRaiser, Logger, LoggerConfig } from './LoggerBase';
import { AsyncTransport, Meta, Ref } from './types';

export interface LoggerExecutorOptions extends LoggerConfig {
  http: string;
}

export class LoggerExecutor {
  rootLogger: Logger;

  constructor(
    config: Ref<LoggerExecutorOptions>,
    private transport: AsyncTransport,
    errorRaiser?: ErrorRaiser,
    defaultSharedMeta?: Meta
  ) {
    this.rootLogger = new Logger(
      { scope: undefined },
      config,
      this.transport,
      {
        current: { ...defaultSharedMeta },
      },
      errorRaiser
    );
  }

  async finalFlush(): Promise<void> {
    return await this.transport.finalFlush();
  }
}

export type Mutable<T> = {
  -readonly [k in keyof T]: T[k];
};

export interface Ref<T> {
  readonly current: Readonly<T>;
}

export type Meta = Record<string, unknown>;

export enum LogType {
  Log = 'log',
  Event = 'event',
}

export enum LogLevel {
  Fatal = 'fatal',
  Error = 'error',
  Warning = 'warn',
  Info = 'info',
  Debug = 'debug',
  Trace = 'trace',
}

export const LevelsValuesLabels = {
  values: {
    fatal: 60,
    error: 50,
    warn: 40,
    info: 30,
    debug: 20,
    trace: 10,
  },
  labels: {
    10: 'trace',
    20: 'debug',
    30: 'info',
    40: 'warn',
    50: 'error',
    60: 'fatal',
  },
} as const;

export interface MinimumLogstashFormat extends Meta {
  createdAt: string; // ISO8601 timestring
  logType: LogType;
  message: string;
  level: LogLevel;
}

export interface AsyncTransport {
  send(o: MinimumLogstashFormat | string): Promise<void>;
  finalFlush(): Promise<void>;
}

/**
 * Exponential backoff calculation and "sleep" via Promise.
 *
 * This algorithm comes from
 * https://cloud.google.com/iot/docs/how-tos/exponential-backoff#example_algorithm,
 * which recommends adding a slight amount of randomness to each backoff
 * interval to prevent accidentally synchronized clients from all retrying at
 * the exact same second.
 */
export async function backoffSleep(
  deliveryAttemptCount: number,
  maxBackoffMs: number,
  setTimeoutImpl = setTimeout,
  randImpl = Math.random
): Promise<void> {
  const randMs = Math.floor(randImpl() * 1000);

  const backoffMs = Math.min(
    Math.pow(2, deliveryAttemptCount) * 1000 + randMs,
    maxBackoffMs + randMs
  );

  if (deliveryAttemptCount > 0) {
    await new Promise((resolve) => setTimeoutImpl(resolve, backoffMs));
  }
}

import { wrap } from 'comlink';

import {
  type AsyncTransport,
  type MinimumLogstashFormat,
} from '@lp-lib/logger-base';

import { type FetchTransport } from './FetchTransport';
import { type TransportConfig } from './QueuedTransport';

const prefix = `[LunaPark Log Worker]`;

const warning = `Failed to transfer log to worker, likely due to structured \
clone failure. Consider removing instances, functions, and other \
non-serializable items from the log data for performance. See \
https://developer.mozilla.org/en-US/docs/Web/API/Web_Workers_API/Structured_clone_algorithm#things_that_dont_work_with_structured_clone. \
These objects stringify as {}. Will attempt JSON serialization.`;

// This primarily exists so that .send() can await the creation of the worker
// without impacting other interfaces or creating unneccessary promises (such as
// .log becoming async).
export class WorkerPoweredTransport implements AsyncTransport {
  transport: Promise<AsyncTransport>;

  constructor(config: TransportConfig) {
    const Transport = wrap<typeof FetchTransport>(
      new Worker(new URL('./logger-fetch.worker.ts', import.meta.url), {
        type: 'module',
      })
    );
    this.transport = new Transport(config);
  }

  async send(o: MinimumLogstashFormat | string): Promise<void> {
    // first try to send the log object itself through postMessage (via
    // comlink). If that fails to structured clone, fallback to JSON string. We
    // could simplify and send the json string always, but that somwhat defeats
    // the point of doing as little work on the main thread as possible.
    try {
      return await (await this.transport).send(o);
    } catch (err) {
      console.warn(`${prefix} ${warning}`);
      console.warn(`${prefix} Original log object:`, o);
      console.warn(`${prefix} postMessage error result follows:`);
      console.error(err);
    }

    try {
      const string = JSON.stringify(o);
      return await (await this.transport).send(string);
    } catch (err) {
      console.warn(`Failed to transfer log to worker. Aborting`, o, err);
    }
  }

  async finalFlush(): Promise<void> {
    return (await this.transport).finalFlush();
  }
}

import { QueuedTransport, type TransportConfig } from './QueuedTransport';

// Has to be a class because comlink cannot easily transfer return values from
// functions.
export class FetchTransport extends QueuedTransport {
  constructor(config: TransportConfig) {
    super(config, {
      setTimeout: setTimeout,
      random: Math.random,
      request: async (input, data, init) => {
        const res = await fetch(input, { ...init, body: data, method: 'POST' });
        if (!res.ok) throw new Error(res.statusText, { cause: res });
        return res;
      },
    });
  }
}

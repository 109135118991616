import { captureException } from '@sentry/remix';

import {
  type AsyncTransport,
  type MinimumLogstashFormat,
} from '@lp-lib/logger-base';

import { backoffSleep } from '../utils/backoffSleep';

const prefix = `[LunaPark Log Transport]`;

type FlushedLogs = {
  logs: (MinimumLogstashFormat | string)[];
  deliveryMeta: { count: number };
};

type RequestImpl = (
  url: string,
  data: BodyInit,
  config?: RequestInit
) => Promise<{ status: number }>;

type InjectedImpls = {
  setTimeout: typeof setTimeout;
  random: () => number;
  request: RequestImpl;
};

interface SenderFn {
  (
    o: FlushedLogs,
    config: TransportConfig,
    impls: InjectedImpls
  ): Promise<void>;
}

export type TransportConfig = {
  http: string;
  // Wait this long between batching/sending enqueued logs
  queueFlushMs: number;
  // Break requests into multiple requests if this number is exceeded
  maxEntryCountPerRequest: number | null;
  // An exponential backoff is used to retry failed log delivery. Once this
  // value is reached, it will be used between retry attempts instead of
  // continuing exponentially.
  maxBackoffMs: number;
  // The maximum number of attempts to deliver a log bundle.
  maxDeliveryAttempts: number;
};

// NOTE: both sendWithRetry and flush are plain functions to avoid capturing
// excessive/unnecessary closures, and to limit state mutations of any classes.

async function sendWithRetry(
  o: FlushedLogs,
  config: TransportConfig,
  impls: InjectedImpls
): Promise<void> {
  if (!config.http) return;

  const { deliveryMeta } = o;

  // NOTE: The first attempt (0) will be immediate.
  await backoffSleep(
    deliveryMeta.count,
    config.maxBackoffMs,
    impls.setTimeout,
    impls.random
  );

  deliveryMeta.count += 1;

  if (deliveryMeta.count > config.maxDeliveryAttempts) {
    const msg = `Exhausted log send retries`;
    captureException(new Error(msg), { extra: o });
    console.warn(`${prefix} msg`, o);
    return;
  }

  try {
    const res = await impls.request(
      config.http,
      // Send line-delimited JSON, assume any existing strings are JSON
      o.logs
        .map((log) => (typeof log === 'string' ? log : JSON.stringify(log)))
        .join('\n'),
      {
        headers: {
          'content-type': 'text/plain',
        },
      }
    );
    if (res.status >= 200 && res.status < 300) return;
  } catch (err) {
    console.warn(`${prefix} Failed to send log event, will retry`, o, err);
  }

  return sendWithRetry(o, config, impls);
}

async function flush(
  queueRef: (MinimumLogstashFormat | string)[],
  config: TransportConfig,
  impls: InjectedImpls,
  sendWithRetry: SenderFn
): Promise<void> {
  if (queueRef.length === 0) return;

  // Dequeue immediately in case more are added
  const queue = queueRef.splice(0, queueRef.length);

  while (queue.length) {
    const batch = queue.splice(
      0,
      config.maxEntryCountPerRequest === null
        ? queue.length
        : config.maxEntryCountPerRequest
    );
    if (batch.length === 0) break;

    await sendWithRetry(
      {
        logs: batch,
        deliveryMeta: { count: 0 },
      },
      config,
      impls
    );
  }

  if (queueRef.length > 0) {
    return flush(queueRef, config, impls, sendWithRetry);
  }
}

export class QueuedTransport implements AsyncTransport {
  private queue: (MinimumLogstashFormat | string)[] = [];
  private queueFlushRef: ReturnType<typeof setTimeout> | null = null;

  constructor(
    private config: TransportConfig,
    private impls: InjectedImpls,
    private flushImpl = flush
  ) {}

  private async kickQueue(immediate = false): Promise<void> {
    // if no batching, immediately flush

    if ((immediate || this.config.queueFlushMs === 0) && this.queue.length) {
      return this.flushImpl(this.queue, this.config, this.impls, sendWithRetry);
    }

    // If batching is enabled without a flush scheduled, schedule a flush

    if (
      this.config.queueFlushMs > 0 &&
      this.queue.length &&
      !this.queueFlushRef
    ) {
      this.queueFlushRef = setTimeout(() => {
        this.queueFlushRef = null;
        this.flushImpl(this.queue, this.config, this.impls, sendWithRetry);
      }, this.config.queueFlushMs);
      return;
    }
  }

  async send(o: MinimumLogstashFormat | string): Promise<void> {
    this.queue.push(o);
    await this.kickQueue();
  }

  async finalFlush(): Promise<void> {
    await this.kickQueue(true);
  }
}

import {
  AsyncTransport,
  LevelsValuesLabels,
  LogLevel,
  LogType,
  Meta,
  Mutable,
  Ref,
} from './types';

function writeRef<T>(ref: Ref<T>, current: T) {
  (ref as Mutable<Ref<T>>).current = current;
}

function formatObjectAsLines(obj?: Record<string, unknown>) {
  return obj
    ? Object.keys(obj)
        .reduce((lines, key) => {
          lines.push(`  ${key}: ${JSON.stringify(obj[key])}`);
          return lines;
        }, [] as string[])
        .join('\n')
    : '';
}

function formatArrayAsLines(arr: unknown[]) {
  return arr.map((item) => JSON.stringify(item)).join('\n');
}

function extractNonError(obj: unknown) {
  if (obj && !(obj instanceof Error)) {
    // An Event is not structured cloneable, for example, so we do a best
    // effort.
    return { ...obj };
  }
}

export interface LoggerConfig {
  level: LogLevel;
  console: boolean;
  verbose: boolean;
  consoleFormat: 'json' | 'message-json' | 'message-lines' | 'message-only';
  enabledScopes: Set<string>;
  knownScopes?: Set<string>;
}

export type ErrorRaiser = (
  err: unknown,
  meta: {
    message: string;
  } & Meta
) => void;

export class Logger {
  private consoleDateFormatter = new Intl.DateTimeFormat('en-US', {
    hourCycle: 'h24',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    fractionalSecondDigits: 3,
  });

  constructor(
    private scopedMeta: { scope: undefined | string } & Meta,
    private config: Ref<LoggerConfig>,
    private transport: AsyncTransport,
    private sharedMeta: Ref<Meta>,
    private errorRaiser?: ErrorRaiser,
    private getTimeMs: () => number = () => Date.now()
  ) {}

  verbose(enable: boolean): void {
    writeRef(this.config, {
      ...this.config.current,
      verbose: enable,
    });
  }

  console(enable: boolean): void {
    writeRef(this.config, {
      ...this.config.current,
      console: enable,
    });
  }

  enableLevel(level: LogLevel): void {
    writeRef(this.config, {
      ...this.config.current,
      level,
    });
  }

  enableScope(scope: string): void {
    writeRef(this.config, {
      ...this.config.current,
      enabledScopes: new Set(this.config.current.enabledScopes).add(scope),
    });
  }

  disableScope(scope: string): void {
    const enabledScopes = new Set(this.config.current.enabledScopes);
    enabledScopes.delete(scope);
    writeRef(this.config, {
      ...this.config.current,
      enabledScopes,
    });
  }

  enabledScopes(): Readonly<Set<string>> {
    return this.config.current.enabledScopes;
  }

  knownScopes(): Readonly<Set<string>> {
    return this.config.current.knownScopes ?? new Set();
  }

  consoleFormat(format: LoggerConfig['consoleFormat']): void {
    writeRef(this.config, {
      ...this.config.current,
      consoleFormat: format,
    });
  }

  /**
   * This updates the shared, global metadata shipped with every log message and
   * used by every logger. Be careful.
   */
  updateSharedMeta(meta: Meta): void {
    if (this.scopedMeta.scope !== undefined) {
      throw new Error('only the root logger can update the shared meta');
    }
    writeRef(this.sharedMeta, { ...this.sharedMeta.current, ...meta });
  }

  updateMeta(meta: Meta): void {
    this.scopedMeta = { ...this.scopedMeta, ...meta };
  }

  scoped(scope: string): Logger {
    writeRef(this.config, {
      ...this.config.current,
      knownScopes: new Set(this.config.current.knownScopes).add(scope),
    });

    return new Logger(
      { scope },
      this.config,
      this.transport,
      this.sharedMeta,
      this.errorRaiser,
      this.getTimeMs
    );
  }

  setTimeMsGetter(getTimeMs: () => number): void {
    this.getTimeMs = getTimeMs;
  }

  log(
    level: LogLevel,
    message: string,
    meta?: Meta | unknown[],
    logType: LogType = LogType.Log
  ): void {
    const scopeEnabled =
      (this.scopedMeta.scope &&
        this.config.current.enabledScopes.has(this.scopedMeta.scope)) ||
      this.config.current.enabledScopes.has('*');
    const scopeDisabled = !scopeEnabled;
    const verbose = this.config.current.verbose;
    const levelEnabled = this.isLevelEnabled(level);

    if (!verbose && (scopeDisabled || !levelEnabled)) return;

    const createdAt = new Date(this.getTimeMs());

    if (this.config.current.console || this.config.current.verbose) {
      if (this.config.current.consoleFormat === 'json') {
        console.log(
          JSON.stringify({
            level,
            message,
            ...this.sharedMeta.current,
            ...this.scopedMeta,
            ...meta,
          })
        );
      } else {
        if (!Array.isArray(meta) && meta && meta['error'] instanceof Error) {
          console.error(meta['error']);
        }

        if (this.config.current.consoleFormat === 'message-json') {
          console.log(
            `${this.consoleDateFormatter.format(createdAt)} LP [${level}] ${
              this.scopedMeta.scope
            }: ${message}\n ${JSON.stringify({
              ...this.sharedMeta.current,
              ...this.scopedMeta,
              ...meta,
            })}`
          );
        } else if (this.config.current.consoleFormat === 'message-lines') {
          const lines = [
            formatObjectAsLines(this.sharedMeta.current),
            formatObjectAsLines(this.scopedMeta),
            !Array.isArray(meta)
              ? formatObjectAsLines(meta)
              : formatArrayAsLines(meta),
          ].join('\n');

          console.log(
            `${this.consoleDateFormatter.format(createdAt)} LP [${level}] ${
              this.scopedMeta.scope
            }: ${message}\n${lines}`
          );
        } else if (this.config.current.consoleFormat === 'message-only') {
          const args: unknown[] = [
            `[${level}] ${this.scopedMeta.scope}`,
            message,
          ];
          if (meta && Array.isArray(meta)) {
            args.push(...meta);
          }
          console.log(...args);
        }
      }
    }

    if (!levelEnabled || level === LogLevel.Trace) return;

    if (
      !Array.isArray(meta) &&
      meta &&
      meta['error'] &&
      meta['error'] instanceof Error
    ) {
      // Not all browsers support structured clone of Error objects.
      // https://bugzilla.mozilla.org/show_bug.cgi?id=1556604 In the meantime,
      // extract and simplify before sending over bridge to the transport.

      // Try to handle `cause` being an event, similar to how we handle `event`.
      const eventCause = extractNonError(meta['error'].cause);
      const otherCause = meta['error'].cause;

      meta['error'] = {
        message: meta['error'].message,
        stack: meta['error'].stack,
        name: meta['error'].name,
        cause: eventCause ?? otherCause,
      };
    }

    if (!Array.isArray(meta) && meta) {
      const extracted = extractNonError(meta['event']);
      if (extracted) meta['event'] = extracted;
    }

    this.transport.send({
      createdAt: createdAt.toISOString(),
      logType: logType,
      level,
      message,
      ...this.sharedMeta.current,
      ...this.scopedMeta,
      ...meta,
    });
  }

  debug(message: string, meta?: Meta): void {
    this.log(LogLevel.Debug, message, meta);
  }

  info(message: string, meta?: Meta): void {
    this.log(LogLevel.Info, message, meta);
  }

  warn(message: string, meta?: Meta): void {
    this.log(LogLevel.Warning, message, meta);
  }

  error(message: string, error: Error | unknown, meta?: Meta): void {
    if (this.errorRaiser) {
      this.errorRaiser(error, {
        message,
        ...this.sharedMeta.current,
        ...this.scopedMeta,
        ...meta,
      });
    }

    const m = Object.assign({ error }, meta);
    this.log(LogLevel.Error, message, m);
  }

  /**
   * Trace is never sent to the transport. This is useful for local development
   * and logging objects that cannot be serialized.
   */
  trace(message: string, ...meta: (Meta | unknown)[]): void {
    this.log(LogLevel.Trace, message, meta);
  }

  // Is this level enabled, regardless of `.verbose` flag?
  isLevelEnabled(level: LogLevel): boolean {
    return (
      LevelsValuesLabels.values[level] >=
      LevelsValuesLabels.values[this.config.current.level]
    );
  }

  // Will this level output to either local or the transport due to Level or
  // Verbose? Useful for expensive-to-format messages
  willOutput(level: LogLevel): boolean {
    return this.config.current.verbose ? true : this.isLevelEnabled(level);
  }
}

import { captureException } from '@sentry/remix';

import { LoggerExecutor, type LogLevel } from '@lp-lib/logger-base';

import config from '../config';
import { getFeatureQueryParamArray } from '../hooks/useFeatureQueryParam';
import { uuidv4 } from '../utils/common';
import { isServer } from '../utils/isServer';
import { FetchTransport } from './FetchTransport';
import { WorkerPoweredTransport } from './WorkerPoweredTransport';

const executor = new LoggerExecutor(
  {
    current: {
      ...config.log,
      level: config.log.level as LogLevel,
      verbose: false,
      enabledScopes: new Set(config.log.enabledScopes),
    },
  },
  getFeatureQueryParamArray('async-worker-logging') === 'enabled' &&
  process.env.NODE_ENV !== 'test' &&
  !isServer()
    ? new WorkerPoweredTransport(config.log)
    : new FetchTransport(config.log),
  (err, meta) => {
    captureException(err, { extra: meta });
  },
  {
    // Add an "instance" to all logger messages to tie all logs from a single
    // app instance together, regardless of user, venue, clientId, etc.
    instance: uuidv4(),
  }
);
/**
 * @deprecated Use `getLogger` instead.
 */
// eslint-disable-next-line import/no-default-export
export default executor.rootLogger;

export function getLogger() {
  return executor.rootLogger;
}

/**
 * Flush the final enqueued logs from the logger worker, and then reload the
 * page. This prevents logs from getting lost due to the performance-oriented
 * log batching.
 */
export async function safeWindowReload(): Promise<void> {
  await executor.finalFlush();
  // eslint-disable-next-line no-restricted-syntax
  window.location.reload();
}
